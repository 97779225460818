<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLiveLabel">{{ confirmTitleTrans }}</h5>
        <button @click="close" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>{{ content }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">{{ resetButtonTextTrans }}</button>
        <button type="button" class="btn btn-primary" @click="$emit('confirm'); close()">{{ confirmButtonTextTrans }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ConfirmationModal',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      required: true,
    },
    confirmButtonText: {
      type: String,
      required: false,
      default: '',
    },
    resetButtonText: {
      type: String,
      required: false,
      default: '',
    },
    close: {
      type: Function,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const confirmTitleTrans = computed(() => {
      return props.title ? props.title : t('confirmAction')
    })
    const confirmButtonTextTrans = computed(() => {
      return props.confirmButtonText ? props.confirmButtonText : t('confirm')
    })
    const resetButtonTextTrans = computed(() => {
      return props.resetButtonText ? props.resetButtonText : t('Close')
    })
    return {
      confirmButtonTextTrans,
      resetButtonTextTrans,
      confirmTitleTrans,
    }
  },
}
</script>
